import React from "react"
import {useAuth0} from "@auth0/auth0-react"

const Profile = ({details}) => {
    const { error, isAuthenticated, isLoading, user } = useAuth0();
    return (
      <>
        <ul>
          <li>Error: {error && error.toString()}</li>
          <li>isAuthenticated: {isAuthenticated.toString()}</li>
          <li>isLoading: {isLoading.toString()}</li>
        </ul>
        { isAuthenticated && details && (
            <div>
              <img src={user.picture} alt={user.name} />
              <h2>{user.name}</h2>
              <p>{user.email}</p>
            </div>
            )
         }
      </>
    )
}

export default Profile