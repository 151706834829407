import React from "react"
import BaseLayout from "../components/BaseLayout";
import LoginButton from "../components/Authentication/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../components/Authentication/LogoutButton";
import { Link } from "gatsby";
import Profile from '../components/Authentication/Profile';

const IndexPage = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <BaseLayout>
      <h1>Home</h1>
      {process.env.BRANCH && <h2>On:{process.env.BRANCH}</h2>}
      <br/>
      {isAuthenticated ? <LogoutButton/> : <LoginButton/>}
      <br/>
      {isAuthenticated ? <Link to="/account">Account</Link> : null}
      <br/>
      <Profile details={false}/>
    </BaseLayout>
  )
}

export default IndexPage
